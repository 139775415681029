import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import * as blogPostStyle from "./blogPost.module.scss"

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "Do MMMM YYYY")
        title
      }
      fields {
        slug
      }
    }
  }
`
const MarkdownPost = ({ data }) => {
  const { title, date } = data.markdownRemark.frontmatter
  const { html } = data.markdownRemark

  return (
    <Layout page={title}>
      <h1 className={blogPostStyle.title}>{title}</h1>
      <h4 className={blogPostStyle.date}>{date.toString()}</h4>
      <div dangerouslySetInnerHTML={{ __html: html }}></div>
    </Layout>
  )
}

export default MarkdownPost